import React from "react"
import Header from "../components/ReportDashboard/Header"
import { ReportDashboardWrapper } from "../components/ReportDashboard/style"
import { Button } from "@material-ui/core"
import { navigate } from "gatsby"

const Error = () => {
  const isBrowser = typeof window !== "undefined"
  const url = isBrowser ? window.location.href : "/"

  return (
    <ReportDashboardWrapper>
      <Header error />
      <div className="error">
        {url.includes("token-expire") && (
          <>
            <p>Your session has expired. Please log in again.</p>
            <Button
              variant="outlined"
              onClick={() => {
                localStorage.removeItem("insightToken")
                navigate("/login")
              }}
            >
              Login
            </Button>
          </>
        )}

        {url.includes("no-access") && (
          <>
            <p>
              You don't have permission to view this project.
              <br />
              Please contact your admin.
            </p>
            <Button
              variant="outlined"
              onClick={() => {
                navigate("/report-dashboard")
              }}
            >
              Dashboard
            </Button>
          </>
        )}
      </div>
    </ReportDashboardWrapper>
  )
}

export default Error
